<template>
  <div class="subjective-wrapper" style="margin: 20px 0;display: flex;flex-direction: column" v-html="subject"></div>
</template>

<script>
export default {
  props: {
    subject: {
      required: true,
      type: String
    },
    showAnswer: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
.subjective-wrapper {
  text-align: left;
}
</style>
