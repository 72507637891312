<template>
  <div>
    <div class="quesStyle" style="margin: 20px 0;display: flex;flex-direction: column" v-html="subject"></div>
    <div v-if="showAnswer" class="options-container">
      <RadioGroup :value="radioAnswer" vertical>
        <Radio label="true">
          <span>正确</span>
        </Radio>
        <Radio label="false">
          <span>错误</span>
        </Radio>
      </RadioGroup>
    </div>
    <div class="exam-result" v-if="showScore">
      <div class="examinee-answer">
        <span>考生答案：</span>
        <span :class="{ error: !correct, right: correct }">{{ userAnswerText }}</span>
      </div>
      <div class="correct-answer">
        <span>正确答案：</span>
        <span>{{ this.answer ? '正确' : '错误' }}</span>
      </div>
      <div class="score">
        <span>得分：</span>
        <span>{{ score }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    subject: {
      required: true,
      type: String
    },
    showAnswer: {
      type: Boolean,
      default: false
    },
    answer: {
      type: Boolean,
      default: false
    },
    showScore: {
      type: Boolean,
      default: false
    },
    score: {
      type: Number,
      default: 0
    },
    userAnswer: {
      required: false
    }
  },
  computed: {
    radioAnswer() {
      return String(this.answer)
    },
    userAnswerText() {
      if (!this.userAnswer) {
        return '无'
      }

      return JSON.parse(this.userAnswer) ? '正确' : '错误'
    },
    correct() {
      if (!this.userAnswer) {
        return false
      }

      return JSON.parse(this.userAnswer) === this.answer
    }
  }
}
</script>

<style lang="less" scoped>
.options-container {
  ::v-deep .ivu-radio-wrapper {
    display: block;
    pointer-events: none;
  }
}

.exam-result {
  display: flex;
  color: #3c88d3;

  .examinee-answer {
    margin-right: 10px;
  }

  .correct-answer {
    margin-right: 10px;
  }

  .error {
    color: #ed4014;
  }

  .right {
    color: #19be6b;
  }
}
.quesStyle {
  text-align: left;
}
</style>
