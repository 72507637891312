<template>
  <div>
    <div class="fill-in-blank-wrapper" style="margin: 20px 0;display: flex;flex-direction: column" v-html="subject"></div>
    <div v-if="showAnswer && !showScore" class="answer">答案：{{ answer }}</div>
    <div class="exam-result" v-if="showScore">
      <div class="examinee-answer">
        <span>考生答案：</span>
        <span v-for="(blank, index) in userBlanks" :key="index" :class="{ error: !correct, right: correct }">
          第 {{ index + 1 }} 空：{{ blank.value }}
        </span>
      </div>
      <div class="correct-answer">
        <span>正确答案：</span>
        <span v-for="(blank, index) in correctBlanks" class="blank" :key="index"> 第 {{ index + 1 }} 空：{{ blank.blankAnswer.join(', ') }} </span>
      </div>
      <div class="score">
        <span>得分：</span>
        <span>{{ score }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    subject: {
      required: true,
      type: String
    },
    showAnswer: {
      type: Boolean,
      default: false
    },
    blanks: {
      type: Array
    },
    showScore: {
      type: Boolean,
      default: false
    },
    score: {
      type: Number,
      default: 0
    },
    userAnswer: {
      required: false
    },
    correct: {
      type: Boolean
    }
  },
  computed: {
    answer() {
      return this.blanks.map(blank => blank.blankAnswer.join(',')).join(' 、 ')
    },
    userBlanks() {
      const blanks = []
      if (!this.userAnswer) {
        return blanks
      }
      for (const blank in this.userAnswer) {
        blanks.push({
          key: blank,
          value: this.userAnswer[blank][0]
        })
      }

      blanks.sort((a, b) => {
        const aOrder = /__(\d+)__/.exec(a.key)[1]
        const bOrder = /__(\d+)__/.exec(b.key)[1]
        return aOrder - bOrder
      })

      return blanks
    },
    correctBlanks() {
      const blanks = this.blanks.concat()
      blanks.sort((a, b) => {
        const aOrder = /__(\d+)__/.exec(a.blankId)[1]
        const bOrder = /__(\d+)__/.exec(b.blankId)[1]
        return aOrder - bOrder
      })

      return blanks
    }
  }
}
</script>

<style lang="less" scoped>
.answer {
  margin-top: 10px;
}

.exam-result {
  color: #3c88d3;

  .examinee-answer,
  .correct-answer,
  .score {
    margin: 10px 0;
  }

  .correct-answer .blank {
    margin-right: 10px;
  }

  .error {
    color: #ed4014;
  }

  .right {
    color: #19be6b;
  }
}
.fill-in-blank-wrapper {
  text-align: left;
}
</style>
