<template>
  <div>
    <div class="quesStyle" style="margin: 20px 0;display: flex;flex-direction: column" v-html="subject"></div>
    <div v-if="showScore">
      <Alert :type="score > 0 ? 'success' : 'error'">
        用户答案：
        <span v-html="answer" />
      </Alert>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Code',
  props: {
    subject: {
      required: true,
      type: String
    },
    showScore: {
      type: Boolean,
      default: false
    },
    score: {
      type: Number,
      default: 0
    },
    userAnswer: {
      required: false
    }
  },
  data() {
    return {
      answer: '<pre>' + this.userAnswer + '</pre>'
    }
  }
}
</script>

<style scoped lang="less">
.quesStyle /deep/ p,
div {
  text-align: left;
}
</style>
